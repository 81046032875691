import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { graphql } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";
import loadable from "@loadable/component";

import "./App.css";
import { Loading } from "@website2018/da-dobsonville";

import Header from "./components/Nav/Header";
import Footer from "./components/Nav/Footer";

const Home = loadable (() => import("./Home"));
const NoMatch = loadable (() => import("./components/Page/NoMatch"));
const People = loadable (() => import("./containers/Content/People"));
const Page = loadable (() => import("./containers/Content/Page"));
const Contact = loadable (() => import("./containers/Content/Contact"));
const PostContainer = loadable (() => import("./containers/Content/PostContainer"));
const Campaigns = loadable (() => import("./containers/Content/Campaigns"));
const Campaign = loadable (() => import("./containers/Content/Campaign"));
const Newsroom = loadable (() => import("./containers/Content/Newsroom"));
const WWGTemplate = loadable (() => import("./components/Page/WWGTemplate"));
const ProvinceContainer = loadable (() => import("./containers/Data/ProvinceContainer"));

const App = (props) => {
  const { loading, provincial, setting } = props;

  if (loading) return <Loading />;

  if (!provincial) return <NoMatch />;

  return (
    <Fragment>
      <Header site={{ ...provincial }} />
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => <Home {...props} site={{ ...provincial }} />}
        />
        <Route
          exact
          path="/:year/:month/:slug"
          render={(routerProps) => {

            if(routerProps.match.params.slug === 'null' || !routerProps.match.params.slug) {
              return <NoMatch/> 
            }else{
                return (
                <PostContainer
                  {...routerProps}
                  url={props.url}
                  site={{ ...provincial }}
                />
              )
            }
           }}
        />
        <Route
          exact
          path="/our-people"
          render={(props) => <People {...props} site={{ ...provincial }} />}
        />
        <Route
          exact
          path="/where-we-govern"
          render={(renderProps) => {
            return (
              <ProvinceContainer
                url={props.url}
                slug={process.env.RAZZLE_SITE_CODE}
                render={(province) => {
                  return (
                    <WWGTemplate
                      page={provincial.province}
                      province={province}
                    />
                  );
                }}
              />
            );
          }}
        />
        <Route
          exact
          path="/about"
          render={(props) => (
            <Page
              {...props}
              id={provincial.options.about}
              site={{ ...provincial }}
            />
          )}
        />
        <Route
          exact
          path="/contact"
          render={(props) => <Contact {...props} site={{ ...provincial }} />}
        />
        <Route
          exact
          path="/newsroom"
          render={(props) => <Newsroom {...props} site={{ ...provincial }} />}
        />
        <Route
          exact
          path="/campaigns"
          render={(props) => <Campaigns {...props} site={{ ...provincial }} />}
        />
        <Route
          exact
          path="/campaigns/:slug"
          render={(props) => {
            if(props.match.params.slug === 'null' || !props.match.params.slug) {
              return <NoMatch/> 
            } else { 
              return <Campaign {...props} site={{ ...provincial }} />
            }} }
        />

        <Route
          exact
          path="/:slug"
          render={(routerProps) => {

            if(routerProps.match.params.slug === 'null' || !routerProps.match.params.slug) {
              return <NoMatch/> 
            }else{
              return (
                <Page
                  {...routerProps}
                  site={provincial.province.slug}
                  slug={routerProps.match.params.slug}
                  {...provincial}
                />
              );
              
            }
            
          }}
        />
        <Route component={NoMatch} />
      </Switch>
      <Footer site={{ ...provincial }} settings={setting} />
    </Fragment>
  );
};

const SITE_QUERY = gql`
  query GetSite($site: String, $taxInput: TaxInputQuery) {
    setting {
      social {
        facebook
        twitter

        youtube
        instagram
        telText
        telNumber
        whatsapp
        whatsappText
        email
        contact
      }
    }
    provincial(site: $site) {
      taxonomy(input: $taxInput) {
        id
        name
        slug
        taxonomy
      }
      news {
        id
        date
        slug
        formattedDate
        title {
          rendered
        }
        category {
          id
          name
        }
        featured_media {
          id
          source_url
          title {
            rendered
          }
          media_details {
            sizes {
              medium { source_url }
            }
          }
        }
      }
      province(slug: $site) {
        id
        slug
        seo
        title {
          rendered
        }
        excerpt {
          rendered
        }
        content {
          rendered
        }
        featured_media {
          id
          source_url
          title {
            rendered
          }
        }
        acf
        asides {
          id
          title {
            rendered
          }
          content {
            rendered
          }
          aside_options
        }
        highlight {
          people {
            id
            slug
            title {
              rendered
            }
            excerpt {
              rendered
            }
            role
            facebook
            twitter
            featured_media {
              id
              source_url
              title {
                rendered
              }
            }
          }
        }
        hero {
          headline
          cta_type
          group_headline
          cta_group {
            title
            link_label
            link_url
          }
          background_image {
            url
          }
          focus_point
        }

        seo

        videos {
          id
          videoId
          title {
            rendered
          }
          excerpt {
            rendered
          }
          category {
            id
            name
          }
          featured_media {
            id
            source_url
            title {
              rendered
            }
          }
        }
      }
      options {
        hero {
          headline
          cta_type
          group_headline
          cta_group {
            title
            link_label
            link_url
          }
          background_image {
            url
          }
          focus_point
        }
        structure
        structureCode
        wardTitle
        wardDescription
        email
        contact
        facebook
        twitter
        twitterHandle
        youtube
        instagram
        newsletterTitle
        ourPeopleTitle
        ourPeopleDescription
        about
        wwg
      }
    }
  }
`;

const withData = graphql(SITE_QUERY, {
  options: (props) => {
    let url;
    if (process.browser) {
      url = window.location.host.replace('-origin', '');
    } else {
      url = props.url;
    }

    return {
      variables: {
        site: url,
        taxInput: { taxonomy: "categories" },
      },
    };
  },
  props: ({ data }) => ({ ...data }),
});

export default withData(App);
